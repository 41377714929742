export const LIST_DASHBOARDS_REQUEST = 'LIST_DASHBOARDS_REQUEST';
export const LIST_DASHBOARDS_SUCCESS = 'LIST_DASHBOARDS_SUCCESS';
export const LIST_DASHBOARDS_ERROR = 'LIST_DASHBOARDS_ERROR';

export const DETAILS_DASHBOARDS_REQUEST = 'DETAILS_DASHBOARDS_REQUEST';
export const DETAILS_DASHBOARDS_SUCCESS = 'DETAILS_DASHBOARDS_SUCCESS';
export const DETAILS_DASHBOARDS_ERROR = 'DETAILS_DASHBOARDS_ERROR';

export const LIST_INCIDENT_REQUEST = 'INCIDENT/LIST_INCIDENT_REQUEST';
export const KANBAN_INCIDENT_REQUEST = 'INCIDENT/KANBAN_INCIDENT_REQUEST';
export const LIST_INCIDENT_SUCCESS = 'INCIDENT/LIST_INCIDENT_SUCCESS';
export const LIST_INCIDENT_ERROR = 'INCIDENT/LIST_INCIDENT_ERROR';
